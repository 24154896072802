// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: arial, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$dark-orange: #a33908;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

//colors
$white: #fbfffeff;
$dark-blue: #0c1b33ff;
$light-blue: rgb(98, 129, 179);
$orange: #dd6031ff;
$dark-orange: rgb(145, 62, 32);
$light-grey: #868686;
$grey-blue:  rgb(66, 88, 117);

//breakpoints
$sm: 'min-width: 576px';
$md: 'min-width: 768px';
$lg: 'min-width: 992px';
$xl: 'min-width: 1200px';
$xxl: 'min-width: 1700px';
$xxxl: 'min-width: 2500px';

$sm-rev: 'max-width: 576px';
$md-rev: 'max-width: 768px';

$custom-880: 'min-width: 880px';
$custom-880-rev: 'max-width: 880px';

//misc
$border: 1px solid $light-grey;
