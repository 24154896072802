.dashboard {
    min-height: 100vh;

    .sidebar {
        border-right: $border;
        padding: 0;
        background-color: $dark-blue;

        .logo {
            margin-top: 20px;
            max-width: 95%;
        }

        menu {
            padding: 0;

            ul {
                padding: 0;
                list-style-type: none;

                li {
                    a {
                        font-size: 0.9rem;
                        text-decoration: none;
                        color: #fff;
                        display: block;
                        padding: 10px;

                        &.active {
                            background-color: $grey-blue;
                            font-weight: bold;
                            color: $dark-blue;
                        }

                        &:hover {
                            background-color: $light-blue;
                            font-weight: bold;
                            color: $dark-blue;
                        }
                    }
                }
            }
        }
    }

    .content {
        padding: 50px;
    }
}
