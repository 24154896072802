.contact-page {
    .col-880-no-padding {
        padding-top: 50px;

        @media($lg) {
            padding-top: 100px;
        }
    }

    .content {
        padding-top: 40px;
        border-top: 1px solid black;

        @media($lg) {
            padding-top: 70px;
        }
    }

    .contact-row {
        margin-bottom: 1px;
        font-size: 20px;

        @media($lg) {
            font-size: 18px;
        }
    }

    .contact-title {
        margin-bottom: 8px;
    }
}
