.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(148, 148, 148, 0.596);
    display: none;
}

.img-upload {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 370px;
    max-width: 100%;
    height: auto;
    background-color: #f3f3f3;
    z-index: 9;

    svg.close {
        width: 20px;
        float: right;

        &:hover {
            cursor: pointer;
                fill: #ff0000;
        }
    }

    img#placeholder {
        display: block;
        max-width: 100%;
        width: 200px;
        margin: 20px auto;

        &:hover {
            cursor: pointer;
        }
    }

    input {
        margin-bottom: 20px;

        &:not(:disabled){
            &:hover {
                cursor: pointer;

                &[type=submit] {
                    background-color: $light-blue;
                    color: $white;
                    font-weight: bold;

                }
            }
        }

        &[type=submit] {
            &.loading {
                background-image: url("../media/loader.gif");
                background-repeat: no-repeat;
                background-position: right;
                background-size: 25px;
                cursor: wait;
            }
        }
    }
}
