.container { 
    height: 100vh;

    & > div {
        height: 100vh;
    }

    .login {
        border: $border;
    }

    .col-md-4 {
        margin-top: auto;
        margin-bottom: auto;
    }
}
