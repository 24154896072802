header {
    min-height: 70px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    min-width: 100%;
    font-family: Fut, Arial, sans-serif;

    @media ($custom-880) {
        min-height: 100px;
        min-width: 600px;
        margin-bottom: 20px;
    }

    @media ($lg) {
        min-width: 800px;
        margin-bottom: 10px;
    }

    @media ($xl) {
        min-width: 1000px;
        margin-bottom: 1vh;
    }

    .menu-icon-holder {
        cursor: pointer;
    }

    .menu-icon {
        width: 25px;
        height: 15px;
        position: relative;
        text-align: right;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        float: right;
        margin-right: 10px;
        margin-top: 23px;

        @media ($sm) {
            width: 33px;
            height: 20px;
            margin-top: 30px;
        }

        &.open {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg);
                    top: -3px;
                    left: 8px;
                }

                &:nth-child(2) {
                    width: 0%;
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    top: 15px;
                    left: 8px;

                    @media ($sm) {
                        top: 20px;
                    }
                }
            }
        }

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: #d46c3f;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            @media ($sm) {
                height: 4px;
            }

            &:nth-child(1) {
                top: 0;
                transform-origin: left center;
            }

            &:nth-child(2) {
                top: 50%;
                transform-origin: left center;
            }

            &:nth-child(3) {
                top: 100%;
                transform-origin: left center;
            }
        }
    }

    .logo-wrapper {
        position: relative;
        height: 60px;

        @media ($sm) {
            min-height: 80px;
        }

        @media ($custom-880) {
            height: 100px;
        }
    }

    .logo {
        display: inline-block;
        position: absolute;
        width: 85%;
        max-width: 480px;
        top: 50%;
        transform: translateY(-50%);

        @media ($sm) {
            max-width: 70%;
        }

        @media ($custom-880) {
            max-width: 95%;
            left: 15px;
        }
    }

    nav.col-12 {
        position: absolute;
        padding: 0;
        vertical-align: top;
        min-height: 41px;
        height: 100vh;
        width: 83vw;
        left: -100%;
        transition: left 0.3s ease-in;
        z-index: 11;
        border-right: 2px solid #758e9b;
        background-color: #ffffff;

        @media ($custom-880) {
            height: 100px;
            display: inline-block;
            position: relative;
            visibility: visible;
            padding-top: 0;
            left: 0;
            border: none;
        }

        &.open {
            position: absolute;
            left: 0;

            @media ($custom-880-rev) {
                min-height: 250px;
            }

            @media ($custom-880) {
                position: relative;
                height: 100px;
                padding-top: 0;
            }
        }

        & > ul {
            padding-left: 0;
            padding-top: 50px;
            margin-bottom: 0;
            position: absolute;
            top: 0;

            @media ($custom-880) {
                top: 50%;
                transform: translateY(-50%);
                padding-top: 0;
                position: absolute;
                right: 30px;
            }

            li {
                list-style-type: none;
                display: inline-block;
                min-width: 35px;
                text-align: left;
                position: relative;
                padding-left: 50px;

                @media ($custom-880) {
                    text-align: center;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                &:hover {
                    & > ul {
                        display: block;
                    }
                }

                & > ul {
                    display: block;
                    position: relative;
                    padding-top: 0;
                    background-color: #ffffff;

                    @media ($custom-880) {
                        display: none;
                        position: absolute;
                        left: 3px;
                        width: 170px;
                        padding: 5px 5px 5px 25px;
                    }

                    @media ($lg) {
                        left: 10px;
                        width: 230px;
                    }

                    &:hover {
                        display: block;
                    }

                    & > li {
                        display: block;
                        padding-left: 10px;

                        @media ($custom-880) {
                            padding-left: 0;
                        }

                        a {
                            font-size: 1.2rem;
                            text-align: left;
                            color: $light-grey;

                            &.active {
                                color:#000000;
                            }

                            @media ($custom-880) {
                                font-size: 1rem;
                                padding-top: 5px;
                            }

                            &:before {
                                content: "-";
                                position: absolute;
                                left: -15px;
                            }

                            &:hover {
                                color: #000000;
                            }
                        }
                    }
                }

                a {
                    text-decoration: none;
                    display: block;
                    color: $light-grey;
                    font-size: 1.75rem;
                    padding: 10px 0px;

                    &.active {
                        color:#000000;
                    }

                    @media ($custom-880) {
                        font-size: 1.35rem;
                        padding: 0;
                    }

                    &:hover {
                        text-decoration: none;
                        color: #000000;

                        &:after {
                            right: 20%;
                        }
                    }
                }
            }
        }
    }
}
