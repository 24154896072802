.edit-row {
    border: $border;
    border-radius: 10px;

    p.title {
        padding: 6px 10px;
        background-color: rgb(92, 170, 243);
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        font-weight: bold;
        color: $white;
        margin-bottom: 0;
    }

    img {
        max-width: 100%;
        max-height: 155px;
    }

    .edit-row-content {
        padding: 10px;

        .card-img-top {
            max-height: 155px;
        }

        .image-card {
            max-height: 230px;
        }
    }
}
