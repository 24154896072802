.slider-control-holder {
    position: relative;
}

.sortable-container {
    width: 100%;

    .image-holder {
        cursor: pointer;
    }
}

.slider-control {
    position: absolute;
    left: 80%;
    transform: translate(-50%);
    z-index: 10;
    top: 15px;

    @media ($sm) {
        left: 50%;
        top: 25px;
    }

    .next, .back {
        cursor: pointer;
        display: inline-block;

        img {
            width: 15px;

            @media ($md) {
                width: 17px;
            }
        }
    }

    .next {
        margin-left: 20px;

        img {
            transform: rotate(180deg);
        }
    }
}

.slider {
    max-width: 100%;

    div.slick-slide > div:nth-child(1) > div:nth-child(1) {
        height: 100%;
    }

    div.slick-slide:nth-child(3) > div:nth-child(1) > div:nth-child(1) {
        height: 100%;
    }

    .slick-track, .slick-list {
        height: 100%;
    }

    .image-info-holder {
        width: 100%;
        height: 60px;
        margin: 0 auto;
        position: relative;

        @media ($lg) {
            min-height: 80px;
        }

        &.hidden {
            display: none;
        }

        .image-info {
            margin-bottom: 30px;
            position: absolute;
            left: 0;
            top: 0;
            font-family: Fut, Arial, sans-serif;
            font-size: 14px;

            @media  ($sm) {
                font-size: 17px;
            }
        }
    }
}
// css copied from browser to set image max 100% height
.slick-initialized .slide-holder-img {
    height: calc(100% - 80px);
}

div.slick-slide:nth-child(3) > div:nth-child(1) {
    max-height: 100%;
}

div.slick-slide:nth-child(3) > div:nth-child(1) > div:nth-child(1) {
    height: 100%;
}

div.slick-slide:nth-child(3) > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
    height: calc(100% - 80px);
}

div.slick-slide:nth-child(3) > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
    height: 100%;
}

div.slick-slide:nth-child(3) > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > img:nth-child(1) {
    max-height: 100%;
}

.slick-initialized {
    max-width: 100%;

    .slick-list {
        max-width: 100%;
    }

    .slide-holder-img {
        align-items: center !important; // I know ;)
        max-width: 100%;
        height: calc(100% - 80px);

        .slider-img-holder {
            position: relative;
            display: inline-block;
            max-width: 100%;
            height: 100%;

            &:hover {
                .next, .back {
                    opacity: 1;
                }
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.slider-img, .slick-slide {
    max-width: 100%;
    height: auto;

    &.hidden {
        display: none;
    }
}
