.image-pick, .single-image-pick {
	display: none;
	position: absolute;
	left: 50%;
	z-index: 9;
	overflow: hidden;
	padding: 10px;
	max-width: 1025px;
    transform: translateX( -50%);
    top: 20px;
    max-height: 95vh;
    min-height: 500px;
    width: 100%;

	ul {
		list-style-type: none;
		margin: 0 auto;
		padding: 0;
        min-height: 400px;
        max-height: calc(95vh - 120px);
		overflow-y: auto;
	}

	li {
		display: inline-block;
	}

	input[type="checkbox"][id^="check"], input[type="radio"][id^="check"] {
		display: none;
	}

	label {
		border: 1px solid #fff;
		padding: 10px;
		display: block;
		position: relative;
		margin: 10px;
		cursor: pointer;
	}

	label:before {
		background-color: white;
		color: white;
		content: " ";
		display: block;
		border-radius: 50%;
		border: 1px solid grey;
		position: absolute;
		top: -5px;
		left: -5px;
		width: 25px;
		height: 25px;
		text-align: center;
		line-height: 25px;
		transition-duration: 0.4s;
		transform: scale(0);
	}

	label img {
		height: auto;
		width: 150px;
		transition-duration: 0.2s;
		transform-origin: 50% 50%;
	}

	:checked + label {
		border-color: #ddd;
	}

	:checked + label:before {
		content: "✓";
		background-color: grey;
		transform: scale(1);
	}

	:checked + label img {
		transform: scale(0.9);
		/* box-shadow: 0 0 5px #333; */
		z-index: -1;
	}
}
