.dashboardMedia {
    .mediaHeader {
        display: block;
        height: 60px;
        margin-bottom: 20px;
    }

    .delete-wrapper {
        margin-bottom: 10px;

        input {
            float: right;
            padding: 5px;
            line-height: 15px;
        }
        
    }

    .card {
        margin-bottom: 30px;
    }

    .card-img-top {
        height: 70px;

        @media ($md) {
            height: 100px;
        }

        @media ($xl) {
            height: 150px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}