.content-wrapper {
    width: 100%;
    max-width: 1150px;
    max-height: 100vh;
    height: 100vh;
    margin: 0 auto;

    @media ($xxxl) {
        max-width: 1300px;
    }
}

html {
    font-size: 16px;

    @media (max-width: 1000px) {
        font-size: calc(10px + .4vw);
    }
}

.new-container {
    padding-right: 15px;
    padding-left: 15px;
}

.w-5 {
    width: 25px;
}

.h-5 {
    height: 25px;
}

.drag-icon {
    height: 20px;
    width: 20px;
}

.col-880-4 {
    @media ($custom-880) {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }
}

.col-880-7 {
    @media ($custom-880) {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }
}

.d-880-none {
    @media ($custom-880) {
        display: none;
    }
}

.col-880-no-padding {
    padding: 0;
    height: calc(100vh - 120px);
}

.offset-880-1 {
    @media ($custom-880) {
        margin-left: 8.3333333333%;
    }
}

.col-880-auto {
    @media ($custom-880) {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
}
